<template>
    <div class="content-wrapper">
        <div class="container-full">
            <section class="content mt-2" v-if="chainData">
                <div class="row">
                    <div class="col-xl-12 my-2">
                        <NetworkBadge :chain="chainData"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 my-2 text-center" >                                    
                        <a class="btn btn-rounded btn-outline btn-primary pull-up" type="button" data-toggle="dropdown"><router-link :to="`/launch/token/`" tag="span">Make your own launchpad</router-link></a>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 mt-2 text-center" >
                        <div class="my-2">
                            <b>Featured</b>
                        </div>

                    <div class="row">
                        <div class="col-md-4 col-sm-12  " v-for="(token, id) in listedTokens" :key="id">   
                                
                            <div class="box pull-up p-4 text-center">
                                    
                                    <i class="cc NVC mr-0"></i> <br>     
                                    
                                    <div class="box pull-up text-center">
                                        starts {{ timeConverter(token.startTime) }}
                                    </div>                                           
                                    <b>
                                        {{ token.name }}
                                        
                                        <br>

                                        {{ token.symbol}}
                                        <br>

                                        {{ token.sale.exchangeRate }} {{ token.symbol }} / {{chainData.nativeCurrency.symbol}}
                                        <br>
                                        <a :href="`${token.URI}`" target="_blank" class="btn btn-sm btn-warning">WEBSITE</a>                                                                                
                                        

                                        <br>

                                        <!-- {{ token.sale }} / 1 {{chainData.nativeCurrency.symbol}} -->

                                        <br><br>
                                         <div class="box pull-up text-center p-2 bg-dark">
                                            <div class="row">
                                                <div class="col-4">Goal <br><small class="text-success">{{ token.sale.targetWei }}</small></div>
                                                <div class="col-4">Ends <br><small class="text-success">{{ timeConverter(token.sale.endTime) }}</small></div>
                                                <div class="col-4">Price <br><small class="text-success">{{ token.sale.exchangeRate }} {{ token.symbol }} / {{chainData.nativeCurrency.symbol}}</small></div>
                                            </div>
                                        </div>
                                        <div class="box text-center">
                                            <div class="progress mb-2">
                                                <div class="progress-bar progress-bar-primary progress-bar-striped progress-bar-animated" role="progressbar" style="width: 60%">
                                                    <span class="text-left pl-2">{{ timeConverter(token.sale.endTime) }}</span>
                                                </div>
                                            </div>
                                        </div>                 

                                        <a class="btn btn-rounded btn-outline btn-primary pull-up" type="button" data-toggle="dropdown"><router-link :to="`/tokens/${ token.address }`" tag="span">Details</router-link></a>
                                    </b>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>



            </section>
            <section class="content" v-else>
                <div class="col-xl-12 text-center">Your browser is not connected to a blockchain.</div>
            </section>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import NetworkBadge from '@/components/NetworkBadge.vue'
// import Web3 from 'web3';
import {ethers} from 'ethers';

export default {
    name: "LaunchPad",
    components: {NetworkBadge},
    props: ['TabSelect'],
    data() {
        return {
            // listedTokens: [],
            web3ethers: ethers,
        }
    },
    computed: { 
        ...mapGetters(['contracts', 'account', 'chain', 'chainData', 'signer', 'provider', 'listedTokens']),
        weiRaised(amount) {

            if(amount > 0) {
                // const web3 = new Web3(this.provider.provider);
                console.log(amount);
                return ethers.utils.formatUnits(amount, 18);
            }
        },
    },
    methods: {
        timeConverter(timestamp){
            var a = new Date(timestamp * 1000);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
            return time;
        }
    },
    async mounted() {

        // if (this.contracts) {
        //     this.getTokenSales();            
        // }
        
    }

}
</script>

<style>

</style>