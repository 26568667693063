import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { apolloProvider } from './plugins'
import './assets/scss/style.scss'
import './filters'
import VueEthereum from 'vue-ethereum'

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueEthereum)

new Vue({
    router,
    store,
    eth: new VueEthereum(),
    // inject apolloProvider here like vue-router or vuex
    apolloProvider,
    render: h => h(App)
}).$mount('#app')
