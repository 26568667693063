<template>
    <div class="content-wrapper">
        <div class="container-full">
            <section class="content" v-if="chainData">
                <div class="row">
                    <div class="col-xl-12 my-2">
                        <NetworkBadge :chain="chainData"/>
                    </div>
                </div>
                
                <div class="row my-2">
                    <div class="col-sm-12 mt-2 text-center" >
                        <div class="my-2">                            
                            <h3>Launch Board</h3>
                            <b>From Zero to I.D.O</b>
                        </div>
                    </div>


                <div class="box">
					<div class="box-header">
						<span class="badge badge-light">Platform Fees: {{  web3ethers.utils.formatUnits(chainData.launchFee, 18) }} {{chainData.nativeCurrency.symbol}} + {{ chainData.platformFeePercentage }} of {{chainData.nativeCurrency.symbol}} raised </span>
					</div>
					<div class="box-body">
						<div class="timeline5">
							<div class="timeline__group">
							  <span class="timeline__year" :class="{'bg-success': step1_complete, 'bg-danger': !step1_complete}">Step 1: <small>Terms & Conditions</small></span>
							  <div class="timeline__box">
								<div class="timeline__date" :class="{'bg-success': step1_complete}">
								  <span class="timeline__day"><i class="fa fa-clock-o" :class="{'mdi mdi-check-all': step1_complete }"></i></span>								  
								</div>
								<div class="timeline__post">
								  <div class="timeline__content text-center">
                                        <div v-if="!step1_complete">
                                            <p>Disclaimer</p>
                                            <p> This process is entirely decentralized, we cannot be held reponsible for incorrect entry of information or be held liable for anything related to your use of our platform. Please ensure you enter all your details to the best accuracy possible and that you are in compliance with your local laws and regulations.</p>
                                            <p>We cannot guarantee there will be no bugs. Use at your own risk!</p>
                                            <p>We recommend that you try first on a testnet to test the platform first before deploying to the mainnet.</p>                       
                                            <button class="btn btn-light" @click="acceptTerms = true">I've read and accept the terms above</button>
                                        </div>
                                        <div v-else>Terms Accepted</div>
                                    </div>
								</div>
							  </div>							 
							</div>

							<div class="timeline__group">
							  <span class="timeline__year" :class="{'bg-success': step2_complete, 'bg-danger': !step2_complete}">Step 2: <small>Token Selction</small></span>
							  <div class="timeline__box">
								<div class="timeline__date" :class="{'bg-success': step2_complete}">
								  <span class="timeline__day"><i class="fa fa-clock-o" :class="{'mdi mdi-check-all': step2_complete }"></i></span>							  
								</div>
								<div class="timeline__post">
                                    <div class="timeline__content text-center">
                                        <div class="mt-2 p-2 text-center" v-if="!step2_complete">
                                            <p>Please Select</p>
                                
                                            <div class="form-group-inline">                        
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="newtoken" v-model="step2_selection">
                                                    <label class="form-check-label" for="inlineRadio1">Create New Token</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="oldtoken" v-model="step2_selection">
                                                    <label class="form-check-label" for="inlineRadio2">Already have a token</label>
                                                </div>
                                            </div>
                                            <div v-if="step2_selection== 'newtoken'">
                                                <div class="text-center my-4" v-if="confirmedTxsLinks.length < 1">
                                                    <h4>Build ERC20 Token</h4> 
                                                    
                                                    <div class="row">
                                                        <div class="col-lg-8 col-md-8 col-sm-12 text-center" style="padding-left: 30px;">
                                                            Create your own crypto token in easy steps
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-12 text-center">                                                
                                                            <div>
                                                                <span class="badge badge-light">Price To Build Token: {{ tokenFee }} {{chainData.nativeCurrency.symbol}}</span>
                                                            </div>    
                                                        </div>
                                                    </div>
                                                    <b-form novalidate class="mt-2">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                <label for="tokenModel">Select Token Model</label>
                                                                <select class="col-12 my-2 p-2 rounded" name="tokenModel" v-model="tokenModel">
                                                                    <option value="standard_token">Standard ERC20 Token "Mintable Token supply"</option>
                                                                    <option value="deflationary_token">Deflationary ERC20 Token "Pre minted supply, Burnable"</option>
                                                                    <option value="taxable_token">Taxable ERC20 "Safemoon Model"</option>
                                                                </select>
                                                                <b-form-input    
                                                                    id="token-name"
                                                                    v-model="tokenName"                        
                                                                    type="text"
                                                                    required
                                                                    :disabled="contractCode"
                                                                    placeholder="Token Name">                            
                                                                </b-form-input>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                <b-form-input    
                                                                    id="token-symbol"
                                                                    v-model="tokenSymbol"
                                                                    type="text"
                                                                    required
                                                                    :disabled="contractCode"
                                                                    placeholder="Token symbol">
                                                                </b-form-input>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                <b-form-input    
                                                                    id="token-name"
                                                                    v-model="tokenInitialSupply"                            
                                                                    type="number"
                                                                    required
                                                                    :disabled="contractCode"
                                                                    placeholder="Initial Supply">
                                                                </b-form-input>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="row mt-2" v-if="readyToBuildToken">
                                                            <div class="col">
                                                                <b-button variant="primary" size="sm"  @click="buildToken()" v-if="!contractCode" :disabled="building">
                                                                    <span v-if="!building">Build</span>
                                                                    <SmallSpinner v-else/>
                                                                </b-button>
                                                                <b-button variant="primary" size="sm" class="float-right btn-danger" @click="deployToken()" :disabled="deploying" v-else>                     
                                                                    <span v-if="!deploying">{{ deployButtonText }}</span>
                                                                    <SmallSpinner v-else/>
                                                                </b-button>
                                                            </div>
                                                        </div>
                                                    </b-form>

                                                </div>

                                                <div class="row mt-2 text-center" v-if="confirmedTxsLinks.length > 0">
                                                    <div class="col-12">
                                                        <div class="row mb-2">
                                                            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                                                <p class="info-text alert alert-success text-center mt-2" v-for="(link, key) in confirmedTxsLinks" :key="key">Congratulations! Your Token have been successfuly created. View confirmation at <a :href="link">{{ link }}</a></p>
                                                                <img class="img img-responsive" src="@/assets/images/svg_icons/medal.svg" alt="Success" width="200"><br><br>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2 text-center">
                                                            <div class="col-4">
                                                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark pull-up">
                                                                    <template v-slot:header>
                                                                        <h6 class="mb-0">Token Name</h6>
                                                                    </template>
                                                                    <b-card-text>
                                                                        <strong>{{ tokenName }}</strong>
                                                                    </b-card-text>
                                                                </b-card>
                                                            </div>
                                                            <div class="col-4">
                                                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark pull-up">
                                                                    <template v-slot:header>
                                                                        <h6 class="mb-0">Symbol</h6>
                                                                    </template>
                                                                    <b-card-text>
                                                                        <strong>{{ tokenSymbol }} </strong>
                                                                    </b-card-text>
                                                                </b-card>
                                                            </div>
                                                            <div class="col-4">
                                                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark pull-up">
                                                                    <template v-slot:header>
                                                                        <h6 class="mb-0">Balance</h6>
                                                                    </template>
                                                                    <b-card-text>
                                                                        <strong>{{tokenInitialSupply}}</strong> {{tokenSymbol}}
                                                                    </b-card-text>
                                                                </b-card>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="row mt-2" v-if="step2_selection== 'oldtoken'">
                                                <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                    <b-form-group
                                                            id="token-addr-group"
                                                            label="Select your token or enter token contract address"
                                                            label-for="token-addr"
                                                            class=""
                                                            description="this bit is quite important, double check it">

                                                        <v-select label="tokenName" :options="tokensInAccount" @input="setSelected" :filter-by="filterBy" v-if="tokensInAccount.length > 0">
                                                            <template v-slot:option="option">
                                                                ({{ option.tokenSymbol }}) {{ option.tokenName }} - {{ option.contractAddress }}
                                                            </template>
                                                        </v-select>

                                                        <b-form-input
                                                            v-else                                
                                                            id="token-addr"
                                                            v-model="form.token"                                
                                                            type="text"
                                                            required
                                                            @change="checkAllowance"
                                                            placeholder="0xABC">
                                                        </b-form-input>


                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-12 col-sm-12">
                                                    <div style="padding-top: 50px;" class="text-center">
                                                        <span class="mx-1 badge badge-dark" v-if="symbol">BALANCE: {{ balance }}</span>
                                                        <span class="mx-1 badge badge-dark" v-if="symbol">SYMBOL: {{ symbol }}</span>
                                                        <span class="mx-1 badge badge-dark" v-if="symbol">DP: {{ decimals }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p><b>Token Selected</b></p>
                                             <div class="text-center">
                                                <span class="mx-1 badge badge-dark" v-if="symbol">BALANCE: {{ balance }}</span>
                                                <span class="mx-1 badge badge-dark" v-if="symbol">SYMBOL: {{ symbol }}</span>
                                                <span class="mx-1 badge badge-dark" v-if="symbol">DP: {{ decimals }}</span>
                                            </div>
                                            <p><a href="#"><small class="text-danger" @click="resetStep2">Change</small></a></p>
                                    
                                        </div>
                                    </div>
								</div>
							  </div>
							</div>

							<div class="timeline__group">
                                <span class="timeline__year" :class="{'bg-success': step3_complete, 'bg-danger': !step3_complete}">Step 3: <small>Pre Sale</small></span>
                                <div class="timeline__box">
                                    <div class="timeline__date">
                                        <span class="timeline__day"><i class="mdi mdi-rocket text-warning font-size-30"></i></span>                                    
                                    </div>
                                    <div class="timeline__post">
                                        <div class="timeline__content" text-center>
                                            <div v-if="!step3_complete">
                                                <div class="text-center" v-if="confirmedTxsLinks.length < 1">
                                                    <h4>Configure Your Token Sale</h4> 

                                                    <b-form novalidate class="mt-2">
                                                        <div class="row">
                                                        
                                                            <div class="col-md-12 col-sm-12">
                                                                <div class="text-center">
                                                                    <span class="mx-1 badge badge-dark" v-if="symbol">BALANCE: {{ balance }}</span>
                                                                    <span class="mx-1 badge badge-dark" v-if="symbol">SYMBOL: {{ symbol }}</span>
                                                                    <span class="mx-1 badge badge-dark" v-if="symbol">DP: {{ decimals }}</span>
                                                                </div>
                                                            </div>
                                                             
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                <label for="SaleTime" class="float-left">Start Time</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-addon">
                                                                        <i class="fa fa-calendar text-success font-size-30"></i>
                                                                    </div>
                                                                    <b-form-input    
                                                                        id="startTime"
                                                                        v-model="startTime"
                                                                        type="date"
                                                                        min="1"
                                                                        required
                                                                        :disabled="contractCode"
                                                                        :placeholder="`Start Time`">
                                                                    </b-form-input>
                                                                </div>                                                                                                                            
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                <label for="SaleTime" class="float-left">End Time</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-addon">
                                                                        <i class="fa fa-calendar text-danger font-size-30"></i>
                                                                    </div>
                                                                    <b-form-input    
                                                                        id="endTime"
                                                                        v-model="endTime"
                                                                        type="date"
                                                                        min="1"
                                                                        required
                                                                        :disabled="contractCode"
                                                                        :placeholder="`End Time`">
                                                                    </b-form-input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                <div class="input-group">
                                                                    <div class="input-group-addon">
                                                                        <i class="mdi mdi-sync-alert text-primary font-size-30"></i>
                                                                    </div>
                                                                    <b-form-input    
                                                                        id="exchangeRate"
                                                                        v-model="exchangeRate"
                                                                        type="number"
                                                                        min="1"
                                                                        required
                                                                        :disabled="contractCode"
                                                                        :placeholder="`Exchange Rate, how many ${symbol} for 1 ${chainData.nativeCurrency.symbol}`">
                                                                    </b-form-input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                <div class="input-group">
                                                                    <div class="input-group-addon">
                                                                        <i class="mdi mdi-wallet text-warning font-size-30"></i>
                                                                    </div>
                                                                    <b-form-input    
                                                                        id="payoutWallet"
                                                                        v-model="payoutWallet"                            
                                                                        type="text"
                                                                        required
                                                                        :disabled="contractCode"
                                                                        placeholder="Wallet to receive payments">
                                                                    </b-form-input>                 
                                                                </div>       
                                                            </div>
                                                           
                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                <div class="input-group">
                                                                    <div class="input-group-addon">
                                                                        <i class="mdi mdi-chart-arc text-success font-size-30"></i>
                                                                    </div>
                                                                    <b-form-input    
                                                                        id="minContribution"
                                                                        v-model="minContribution"                            
                                                                        type="number"
                                                                        min="1" 
                                                                        :max="balance"
                                                                        required
                                                                        :disabled="contractCode"
                                                                        :placeholder="`Minimum Contribution Amount in ${chainData.nativeCurrency.symbol}`">
                                                                    </b-form-input>   
                                                                </div>                     
                                                            </div>

                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                <div class="input-group">
                                                                    <div class="input-group-addon">
                                                                        <i class="mdi mdi-chart-arc text-danger font-size-30"></i>
                                                                    </div>
                                                                    <b-form-input    
                                                                        id="maxContribution"
                                                                        v-model="maxContribution"                            
                                                                        type="number"
                                                                        min="1" 
                                                                        :max="balance"
                                                                        required
                                                                        :disabled="contractCode"
                                                                        :placeholder="`Maximum Contribution Amount in ${chainData.nativeCurrency.symbol}`">
                                                                    </b-form-input>         
                                                                </div>               
                                                            </div>

                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                <div class="input-group">
                                                                    <div class="input-group-addon">
                                                                        <i class="mdi mdi-checkbox-multiple-marked-circle-outline text-success font-size-30"></i>
                                                                    </div>
                                                                    <b-form-input    
                                                                        id="targetWei"
                                                                        v-model="targetWei"                            
                                                                        type="number"
                                                                        min="1" 
                                                                        :max="balance"
                                                                        required
                                                                        :disabled="contractCode"
                                                                        :placeholder="`Target Amount in ${chainData.nativeCurrency.symbol}`">
                                                                    </b-form-input>       
                                                                </div>                 
                                                            </div>



                                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
                                                
                                                                <i class="mdi mdi-clock-end font-size-40 text-warning"></i>
                                                                <h4>Token Vesting</h4>
                                                                <div class="form-group-inline">                        
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="radio" name="haveLock0" id="haveLock0" value="0" v-model="haveLock">
                                                                        <label class="form-check-label" for="haveLock0"><i class="mdi mdi-lock-open-outline text-success"></i> No Token Lock</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="radio" name="haveLock1" id="haveLock1" value="1" v-model="haveLock">
                                                                        <label class="form-check-label" for="haveLock1"><i class="mdi mdi-lock-outline text-danger"></i></i> Add a Token Lock</label>
                                                                    </div>
                                                                </div>
                                                                <div v-if="haveLock== 1">
                                                                    <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                        <b-form-input    
                                                                            id="LockPercentage"
                                                                            v-model="LockPercentage"                            
                                                                            type="number"
                                                                            min="1" 
                                                                            :max="balance"
                                                                            required
                                                                            :disabled="contractCode"
                                                                            :placeholder="`Locking Percentage %`">
                                                                        </b-form-input>                        
                                                                    </div>
                                                                    <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                                                        <b-form-input    
                                                                            id="token-name"
                                                                            v-model="LockPeriod"                            
                                                                            type="number"
                                                                            min="1" 
                                                                            :max="balance"
                                                                            required
                                                                            :disabled="contractCode"
                                                                            :placeholder="`Locking Period in days`">
                                                                        </b-form-input>                        
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="row mt-2" v-if="saleConfigured">
                                                            <div class="col">
                                                                <b-button variant="primary" size="lg" class="float-right" @click="buildToken()" v-if="!contractCode" :disabled="building">
                                                                    <span v-if="!building">Build</span>
                                                                    <SmallSpinner v-else/>
                                                                </b-button>
                                                                <b-button variant="primary" size="lg" class="float-right btn-danger" @click="deployToken()" :disabled="deploying" v-else>                     
                                                                    <span v-if="!deploying">{{ deployButtonText }}</span>
                                                                    <SmallSpinner v-else/>
                                                                </b-button>
                                                            </div>
                                                        </div>
                                                    </b-form>

                                                </div>

                                                <div class="row mt-2 text-center" v-if="confirmedTxsLinks.length > 0">
                                                    <div class="col-12">
                                                        <div class="row mb-2">
                                                            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                                                <p class="info-text alert alert-success text-center mt-2" v-for="(link, key) in confirmedTxsLinks" :key="key">Your Token Sale have been successfuly created. View confirmation at <a :href="link">{{ link }}</a></p>
                                                                <img class="img img-responsive" src="@/assets/images/svg_icons/medal.svg" alt="Success" width="200"><br><br>                        
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2 text-center">
                                                            <div class="col-4">
                                                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark">
                                                                    <template v-slot:header>
                                                                        <h6 class="mb-0">Token</h6>
                                                                    </template>
                                                                    <b-card-text>
                                                                        <strong>{{ symbol }}</strong>
                                                                    </b-card-text>
                                                                </b-card>
                                                            </div>
                                                            <div class="col-4">
                                                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark">
                                                                    <template v-slot:header>
                                                                        <h6 class="mb-0">Rate</h6>
                                                                    </template>
                                                                    <b-card-text>
                                                                        <strong>{{ rate }} {{ symbol }} / {{chainData.nativeCurrency.symbol}} </strong>
                                                                    </b-card-text>
                                                                </b-card>
                                                            </div>
                                                            <div class="col-4">
                                                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark">
                                                                    <template v-slot:header>
                                                                        <h6 class="mb-0">Allowance</h6>
                                                                    </template>
                                                                    <b-card-text>
                                                                        <strong>{{ ApprovedAllowance }}</strong>
                                                                    </b-card-text>
                                                                </b-card>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-2">
                                                            <div class="col-12 text-center">
                                                                <a href="/build">Start Over</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p>Token Sale Configured</p>
                                                <p><small class="text-danger">Change</small></p>
                                        
                                            </div>

                                        </div>
                                    </div>
                                </div>
							</div>

                            <div class="timeline__group">
                                <span class="timeline__year" :class="{'bg-success': symbol, 'bg-danger': !symbol}">Step 4: <small>Exchange Listing</small></span>
                                <div class="timeline__box">
                                    <div class="timeline__date">
                                        <span class="timeline__day"><i class="mdi mdi-rocket text-warning font-size-30"></i></span>                                    
                                    </div>
                                    <div class="timeline__post">
                                        <div class="timeline__content">
                                            <p>
                                                content
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="timeline__group">
                                <span class="timeline__year" :class="{'bg-success': symbol, 'bg-danger': !symbol}">Step 5: <small>Liquidity Locking</small></span>
                                <div class="timeline__box">
                                    <div class="timeline__date">
                                        <span class="timeline__day"><i class="mdi mdi-rocket text-warning font-size-30"></i></span>                                    
                                    </div>
                                    <div class="timeline__post">
                                        <div class="timeline__content">
                                            <p>
                                                content
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                        
                        </div>
					</div>                
				</div>





                
                



                 

                  

                </div>



            </section>
            <section class="content" v-else>
                <div class="col-xl-12 text-center">Your browser is not connected to a blockchain.</div>
            </section>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import NetworkBadge from '@/components/NetworkBadge.vue'
// import Web3 from 'web3';
import {ethers} from 'ethers';
import TxPrice from '../components/TxPrice.vue';
import LaunchPad from '../truffleconf/LaunchPad';
import utils from '../utils';
import erc20Abi from '../abi/erc20.abi';
import { times } from "lodash";

export default {
    name: "Token",
    components: {NetworkBadge},
    props: ['TabSelect'],
    data() {
        return {
            acceptTerms: false,
            step2_selection: null,
            tokenSelected: false,
            step2_tokenAddress: null,
            haveLock: null,
            lockPeriod: null,
            lockPercentage: null,

            saleConfigured: false,
            // step3_complete: false,

            web3ethers: ethers,
            platformFeePercentage: 0,

            tokenModel: 'standard_token',
            tokenName: null,
            tokenSymbol: null,
            tokenDecimals: null,
            tokenInitialSupply: null,
            contractCode: null,
            constructor: null,
            building: false,
            deploying: false,
            deployButtonText: 'Deploy',
            confirmedTxsLinks: [],


            allowance: null,
            symbol: null,
            decimals: null,
            balance: null,
            rate: null,
            wallet: null,
            sourceWallet: null,
            allowanceAmount: null,
            approvedAllowance: null,
            
            
            
            approving: null,
            approveTx: null,            
            form: {
                token: null,
            },

        }
    },
    computed: { 
        ...mapState(['tokensInAccount', 'listedTokens']),
        ...mapGetters(['contracts', 'account', 'chain', 'chainData', 'signer', 'provider', 'listedTokens']),
        weiRaised(amount) {
            if(amount > 0) {
                // const web3 = new Web3(this.provider.provider);
                console.log(amount);
                return ethers.utils.formatUnits(amount, 18);
            }
        },
        tokenFee() {
            return ethers.utils.formatUnits(this.chainData.tokenFee, 18);
        },
        step1_complete() {
            if( this.acceptTerms === false) return false 
            else return true;            
        },
        step2_complete() {
            if (!this.step1_complete) return false;
            else if (this.symbol === null) return false;
            else return true;
        },
        step3_complete() {
            if (!this.step1_complete) return false;
            if (!this.step2_complete) return false;
            else if (!this.saleConfigured) return false;
            else return true;
        },
        readyToBuildPresale() {
            if (this.form.token === null) return false;
            else if (this.rate === null) return false;
            else if (this.wallet === null) return false;
            else if (this.sourceWallet === null) return false;
            else return true;
        },
    },
  
   
    async mounted() {
        // const {LaunchPad} = this.contracts;

        // if (this.contracts) {
        //    await this.getTokenSales();                    
        // }
        
    },

      methods: {
        async setSelected(value) {
            this.form.token = value ? value.contractAddress : '';
            this.tokenSelected = true;
            this.checkAllowance();
        },
        async checkAllowance() {
            console.log('checkAllowance');
            if (this.form.token && this.account) {
                const LaunchPadAddress = utils.getContractAddressFromTruffleConf(LaunchPad, this.chain.chainId)
                const erc20 = new ethers.Contract(
                    this.form.token,
                    erc20Abi,
                    this.signer,
                );

                this.symbol = await erc20.symbol();
                this.decimals = await erc20.decimals();

                const weiBalance = (await erc20.balanceOf(this.account));
                this.balance = ethers.utils.formatUnits(weiBalance, this.decimals);
                console.log('balance', weiBalance.toString());
                this.allowance = ethers.utils.formatUnits((await erc20.allowance(this.account, LaunchPadAddress)), this.decimals);
                console.log('allowance', this.allowance.toString());
            } else {
                this.decimals = '';
                this.symbol = '';
                this.balance = '';
                this.allowance = '';
            }
        },
        resetStep2() {
            this.form.token = null;
            this.symbol = null;
            this.tokenSelected = false;
        }
    },

    watch: {
        'symbol': function (newVal, oldVal) {  if (newVal != null) {this.tokenSelected = true;} }
    }

}
</script>

<style>

</style>