<template>
    
    <div class="content-wrapper">
        <div class="container-full">
            <!-- Main content -->
            
                
            <section class="content" v-if="chainData">
                <div class="row">
                    <div class="col-xl-12 mb-2">
                        <NetworkBadge :chain="chainData"/>
                    </div>
                </div>
                                    
                <div class="row">
                

                    <div class="col-xl-8 col-12">
                        <div class="box pull-up">
                            <div class="box-header">
                                <h4 class="box-title">
                                    <i class="cc NVC"></i>
                                    Buy {{ tokenDetails[1]}}
                                </h4>
                            </div>
                            <BuyToken :token="`${$route.params.address}`" />
                        </div>

                        <div class="box pull-up">
                            <div class="box-body">
                                <div class="d-md-flex justify-content-between align-items-center d-inline-block">
                                    <h3 class="my-0">{{ tokenDetails[1] }}</h3>
                                    <br>
                                    <p class="mb-5 text-fade text-sm">{{ $route.params.address }}</p>
                                    <div>
                                        <p class="mb-5 text-fade">Price</p>
                                        <h4 class="mb-0"><span class="text-success">{{ exchangeRate }} / BNB </span></h4>
                                    </div>
                                
                                
                                
                                </div>

                                <div class="table-responsive">
                                    <table class="table no-border">
                                        <tr>
                                            <td>
                                                <div class="new-progress-wrap px-25">
                                                    <ul class="new-progress-line row list-unstyled mt-80 mb-50">
                                                    <li class="col-10 done-1 bg-primary">
                                                        <div class="history text-dark">{{ weiRaised(saleDetails[9]) }} BNB raised</div>
                                                        <div class="scale text-dark">
                                                            Presale
                                                        </div>
                                                    </li>
                                                    
                                                    
                                                    <li class="col-2 done-6">                                                       
                                                        <div class="history text-dark float-right">Goal!</div>
                                                        <div class="scale last text-dark">
                                                            {{ weiRaised(this.saleDetails[8]) }} BNB
                                                        </div>
                                                    </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                        </div>	
                                                

                        <div class="box bg-primary pull-up">						
                            <div class="box-body">
                                <div class="row">
                                    <div class="col-lg-6 col-12">
                                        <h4 class="text-white mb-50">Overview </h4>
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="icon">
                                                    <i class="fa fa-trophy"></i>
                                                </div>
                                                <div class="w-100">
                                                    <h3 class="font-weight-600 text-white mb-0 mt-0">340</h3>
                                                    <p class="text-white-50">Market</p>
                                                    <h5 class="text-white">+34k <span class="ml-10"><i class="fa fa-angle-down mr-5"></i><span class="text-white-50">0.1%</span></span> </h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div id="apexChart2" class="mx-50 mx-xs-20"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <h4 class="text-white mb-50">Sales Overview </h4>
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="icon">
                                                    <i class="fa fa-trophy"></i>
                                                </div>
                                                <div class="w-100">
                                                    <h3 class="font-weight-600 text-white mb-0 mt-0">$9.00</h3>
                                                    <p class="text-white-50">Trading</p>
                                                    <h5 class="text-white">-6.2k  <span class="ml-10"><i class="fa fa-angle-down mr-5"></i><span class="text-white-50">2%</span></span> </h5>
                                                </div>										
                                            </div>
                                            <div>
                                                <div id="apexChart3" class="mx-50 mx-xs-20"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-xl-4 col-12">
                        
                        <div class="box pull-up">
                            <div class="box-header">
                                <h4 class="mb-0 font-weight-500"><i class="cc GDC"></i>Token Details</h4>
                            </div>
                            <div class="box-body">
                                <div class="row my-2">
                                    <div class="col-12 d-flex justify-content-center">
                                        <a :href="`${tokenDetails[3]}`" target="_blank" class="btn btn-warning">WEBSITE</a>                                            
                                    </div>
                                </div>
                                <div class="row mb-30">
                                    <div class="col-12 col-lg-6">                                            
                                        <h5 class="p-15 mb-0"><strong>Token Type:</strong> ERC20</h5>
                                        <h5 class="p-15 mb-0"><strong>Name:</strong> {{ tokenDetails[1] }}</h5>
                                        <h5 class="p-15 mb-0"><strong>SYMBOL:</strong> {{ tokenDetails[2] }}</h5>
                                        <h5 class="p-15 mb-0"><strong>Token Contract <i class="fa fa-copy pull-up"></i></strong> <small><a :href="`${chainData.explorerURL}/address/${$route.params.address}`" target="_blank">{{ $route.params.address }}</a></small></h5>
                                        <h5 class="p-15 mb-0"><strong>Sale Contract <i class="fa fa-copy pull-up"></i></strong> <small><a :href="`${chainData.explorerURL}/address/${saleDetails[0]}`" target="_blank">{{ saleDetails[0] }}</a></small></h5>
                                        <h5 class="p-15 mb-0"><strong>Fundraising Goal:</strong> {{ saleDetails[9] }}</h5>
                                    </div>
                                    
                                    <div class="col-12 col-lg-6">
                                        <h5 class="p-15 mb-0"><strong>Total Supply:</strong> {{ totalSupply }}</h5>
                                        <h5 class="p-15 mb-0"><strong>Available for Token Sale:</strong><span class="text-success"> 90%</span></h5>
                                        <h5 class="p-15 mb-0"><strong>Unsold Tokens:</strong> Will be burned</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box pull-up">
                            <div class="box-header">
                                <h4 class="mb-0 font-weight-500"><small><i class="mdi mdi-lock-outline font-size-40 text-warning"></i></small> {{ tokenDetails[2] }} Lock Overview</h4>
                            </div>
                            <div class="box-body">
                                <div class="row mb-30">                                        
                                    <div class="col-12 col-lg-12">

                                        <div class="col-12 col-lg-6">                                            
                                            <h5 class="p-15 mb-0"><strong>{{ tokenDetails[2] }} locked:</strong> {{ totalLocked }}</h5>
                                            <h5 class="p-15 mb-0"><strong>Lock Contract <i class="fa fa-copy pull-up"></i></strong> <small><a :href="`${chainData.explorerURL}/address/${lockDetails[3]}`" target="_blank">{{ lockDetails[3] }}</a></small></h5>
                                            <h5 class="p-15 mb-0"><strong>Locking Period:</strong> {{ formattedPeriod(parseInt(lockDetails[1]))  }}</h5>
                                            <h5 class="p-15 mb-0"><strong>Locking Percentage:</strong> {{ (parseInt(lockDetails[2]) / 100) }}%</h5>
                                        
                                         </div>
                                    </div>
                                </div>    

                            </div>
                        </div>
                    </div>

                </div>
                

                    
                

                


            </section>
            <section class="content" v-else>
                <div class="col-xl-12 text-center">Your browser is not connected to a blockchain.</div>
            </section>
        
        </div>
    </div>
    
</template>



<script>
import {mapGetters, mapState} from 'vuex';
import NetworkBadge from '@/components/NetworkBadge.vue';
import BuyToken from '@/components/BuyToken.vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import {ethers} from 'ethers';
import erc20Abi from '@/abi/erc20.abi';

export default {
    name: "TokenDetails",
    components: {NetworkBadge, BuyToken, VueQrcode},
    props: ['TabSelect'],
    data() {
        return {
            totalSupply: 0,
            tokenDetails: null,
            saleDetails: null,
            lockDetails: null,
            totalLocked: 0,
            exchangeRate: 0,
            web3ethers: ethers,
        }
    },
    computed: {         
        ...mapGetters(['contracts', 'account', 'chain', 'chainData', 'signer', 'provider']),       
    },
    methods: {
        
       weiRaised(amount) {
            
            if(amount > 0) {
                // const web3 = new Web3(this.provider.provider);
                console.log(amount);
                return ethers.utils.formatUnits(amount, 18);
            }
        },

         formattedPeriod(delta) {            
            
            var months = Math.floor(delta / 2592000);
            delta -= months * 2592000;

            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = delta % 60;  // in theory the modulus is not required

            return `${months}Mo - ${days}D - ${hours}h - ${minutes}m - ${seconds}s`
        }        
    },
    async mounted() {
        console.log("TokenDetails Component mount");

        await window.ethereum.enable()
        const provider = new ethers.providers.Web3Provider(web3.currentProvider)
        const signer = provider.getSigner()
        const chain = await provider.getNetwork()
    
        await this.$store.dispatch('bootstrapWeb3', {provider, signer, chain})

         const erc20 = new ethers.Contract(
            this.$route.params.address,
            erc20Abi,
            signer,
        );

        let supply = await erc20.totalSupply();
        console.log("Supply: "+supply);
        this.totalSupply = ethers.utils.formatUnits(supply, 18).replace(/\B(?=(\d{3})+(?!\d))/g, ",");


        if (this.contracts) {
            const {LaunchPad} = this.contracts;           
            this.tokenDetails = await LaunchPad.listedTokens(this.$route.params.address);   
            this.saleDetails = await LaunchPad.listedTokens_Sale(this.$route.params.address);  
            this.lockDetails = await LaunchPad.listedTokens_Lock(this.$route.params.address);  
            let locked = await erc20.balanceOf(this.lockDetails[3]);
            this.totalLocked = ethers.utils.formatUnits(locked ,18);
            this.exchangeRate = parseInt(this.saleDetails[4]._hex); 
        }
        
    }

}
</script>

<style>

</style>