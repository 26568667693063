<template>
    <header class="main-header">
      <div class="d-flex align-items-center logo-box pl-20">		
          <a href="#" class="waves-effect waves-dark nav-link rounded d-none d-md-inline-block push-btn" data-toggle="push-menu" role="button">
              <img src="/images/svg-icon/collapse.svg" class="img-fluid svg-icon" alt="">
          </a>
          <!-- Logo -->
          <a href="/" class="logo">
              <!-- logo-->
              <div class="logo-lg d-none d-md-inline-block">
                  <span class="light-logo"><img src="/images/logo-dark-text.png" alt="logo"></span>
                  <span class="dark-logo"><img src="/images/logo-light-text.png" alt="logo"></span>
              </div>
          </a>
      </div>  
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top pl-10">
          <!-- Sidebar toggle button-->
          <div class="app-menu">
              <ul class="header-megamenu nav">
                  <li class="btn-group nav-item d-md-none">
                      <a href="#" class="waves-effect waves-light nav-link rounded push-btn" data-toggle="push-menu" role="button">
                          <img src="/images/svg-icon/collapse.svg" class="img-fluid svg-icon" alt="">
                      </a>
                  </li>
                  <!-- <li class="btn-group nav-item">
                      <a href="#" data-provide="fullscreen" class="waves-effect waves-light nav-link rounded full-screen" title="Full Screen">
                          <img src="/images/svg-icon/fullscreen.svg" class="img-fluid svg-icon" alt="">
                      </a>
                  </li> -->
                  <li class="btn-group d-md-inline-flex d-none">
                      <div class="search-bx ml-10">
                          <form>
                              <div class="input-group">
                                  <input type="search" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                                  <div class="input-group-append">
                                  <button class="btn" type="submit" id="button-addon2"><img src="/images/svg-icon/search.svg" class="img-fluid" alt="search"></button>
                                  </div>
                              </div>
                          </form>
                      </div>                                             
                                   
                  </li>
                
                
              
              
                
              </ul> 
          </div>
          
       
      

         <div class="navbar-custom-menu r-side">
            <div style="display: inline" v-if="account">
                <div class="bg-lightest rounded-left px-1" style="display: inline">
                    <small class="text-grey">
                        {{ Number(accountBalance).toFixed(4) }} <b>{{ chainData.nativeCurrency.symbol }}</b>
                    </small>
                </div>
                <div class="bg-dark rounded" style="display: inline">

                    <small class="text-grey mx-1">
                        {{ account.substring(0, 6) }}....{{ account.substring(account.length, account.length-5) }}                    
                    </small>
                    <span class="badge bg-info-light text-danger">
                        <i class="fa fa-circle text-success"></i> <b>{{chainData.chain.substring(0, 10)}}</b>
                    </span> 
                    
                </div>
            </div>
            <div style="display: inline" v-else>
                <div class="bg-dark rounded" style="display: inline">                    
                    <span class="badge bg-info-light text-danger pull-up" @click="connectWeb3()">
                        <a href="#" class="text-danger"><i class="fa fa-circle text-danger"></i> <b>Connect</b></a>
                    </span>                 
                  </div>
            </div>

            <div class="bg-dark rounded ml-1 p-1" title="Toggle Dark / Light" style="display: inline">                
                <i class="mdi mdi-white-balance-sunny text-warning mx-2 waves-effect waves-light font-size-20" id="lightIcon" @click="darkMode = !darkMode"></i>
            </div>
            <div class="bg-dark rounded mx-2 p-1" data-toggle="control-sidebar" title="Setting" style="display: inline">
                <i class="fa fa-cog text-light mx-2 waves-effect waves-light"></i>
            </div>
            
                        
        </div>

        

        


       
       
       


      </nav>
    </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Identicon from 'identicon.js';
import { ethers } from 'ethers'
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

// import web3Connect from '@/web3ConnectService';


export default {
    name: "Header",
    data() {
        return {            
            darkMode: true,
        }
    },
    
    computed: {        
        ...mapGetters(['lang', 'contracts', 'account', 'accountBalance', 'chain', 'chainData', 'signer', 'provider', 'listedTokens']),
        
    },
    methods: {
        ...mapActions(['changeLang', 'connectWeb3', 'changeTheme']),
        
        accountImage(account) {
            return new Identicon(account, 30).toString();
        },
            
        async old_connectWeb3() {


            const providerOptions = {
                walletconnect: {
                    display: {
                    name: "Wallet Connect"
                    },
                    package: WalletConnectProvider,
                    options: {
                        infuraId: "981fbf6b05d9405999f9e71474eb04a1",
                         rpc: {
                            56: 'https://bsc-dataseed1.binance.org',
                            97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
                        }, 
                    }
                }
            };

            const web3Modal = new Web3Modal({
                network: "mainnet", // optional
                cacheProvider: false,
                theme: "dark",
                // disableInjectedProvider: true,
                providerOptions,                               
                });

            web3Modal.clearCachedProvider();
           

            var modalprovider = await web3Modal.connect();

            console.log(modalprovider);
            
            var provider = new ethers.providers.Web3Provider(modalprovider);
            // console.log(provider.provider);            
            var signer = await provider.getSigner();
            console.log(signer);
            var chain = await modalprovider.chainId;
            console.log(chain);

            await this.$store.dispatch('bootstrapWeb3', {provider, signer, chain});


             provider.on("accountsChanged", () => {            
                document.location.reload();
            });
            provider.on("chainChanged", () => {
                document.location.reload();
            });



        },

        toggleDarkMode() {            
            var $sidebar = $('body')        
            var $icon = $('#lightIcon')      

            if ($sidebar.hasClass('dark-skin')) {
                $icon.removeClass('mdi mdi-white-balance-sunny text-warning')
                $icon.addClass('mdi mdi-weather-night')
                $sidebar.removeClass('dark-skin')
                $sidebar.addClass('light-skin')
            } else {
                $icon.removeClass('mdi mdi-weather-night')
                $icon.addClass('mdi mdi-white-balance-sunny text-warning')
                $sidebar.removeClass('light-skin')
                $sidebar.addClass('dark-skin')
            }
        }

    },
    watch:{
        'darkMode': async function (newVal, oldVal) {
            this.toggleDarkMode();
            this.changeTheme(this.darkMode? 'dark': 'light');
        }
    }
}
</script>

<style scoped>

</style>
