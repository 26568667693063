<template>
    <div>
        <section class="banner inner-banner">
            <div class="container text-center">
                <h2 class="fs-title">Affiliate programme</h2>
            </div>
        </section>

        <section class="referral">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 m-auto">
                        <h5>Referral programme</h5>
                        <p>You can earn a referral fee when people purchase credits using your referral link.</p>
                        <p>Sign up to the Multisend.org referral programme today to receive a 15% commission every time a credit purchase is made with your referral link! </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "AffilateProgramme"
}
</script>

<style scoped>

</style>
