<template>
  <footer class="main-footer">
      <div class="pull-right d-none d-sm-inline-block">
          <ul class="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
          <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)">FAQ</a>
          </li>
      </ul>
      </div>
      &copy; 2020 <a href="https://tokens.ninja">Tokens Ninja</a>. All Rights Reserved.
  </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>

</style>
