!<template>
    <aside class="main-sidebar">
        <section class="sidebar" style="height: auto;">	
            <!-- sidebar menu-->
            <ul class="sidebar-menu tree" data-widget="tree">
        
                <router-link to="/" tag="li">
                    <a>
                        <img src="/images/svg-icon/sidebar-menu/dashboard.svg" class="svg-icon" alt="">
                        <span>Home</span>
                    </a>    
                </router-link>

                 <router-link to="/launch" tag="li">
                    <a>
                        <img src="/images/svg-icon/sidebar-menu/rocket.svg" class="svg-icon" alt="">
                        <span>Launchpad</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-right pull-right"></i>
                        </span>
                    </a>
                </router-link>
            
                <router-link to="/build" tag="li">
                    <a>
                        <img src="/images/svg-icon/sidebar-menu/advancedui.svg" class="svg-icon" alt="">
                        <span>BUILD</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-right pull-right"></i>
                        </span>
                    </a>
                </router-link>
                   
            
                <router-link to="/distribute" tag="li">
                    <a>
                        <img src="/images/svg-icon/sidebar-menu/forms2.svg" class="svg-icon" alt="">
                        <span>DISTRIBUTE</span>                
                    </a>
                </router-link>		  

                <router-link to="/exchange" tag="li">
                    <a>
                        <img src="/images/svg-icon/sidebar-menu/transactions.svg" class="svg-icon" alt="">
                        <span>Exchange</span>
                    </a>
                    
                </router-link>
            
                <li class="header">SUPPORT </li>
                
                <router-link to="/contactus" tag="li">
                    <a>
                        <img src="/images/svg-icon/sidebar-menu/mailbox.svg" class="svg-icon" alt="">
                      <span>Contact us</span>
                    </a>
                </router-link>
            
                <router-link to="/settings" tag="li">
                    <a>
                        <img src="/images/svg-icon/sidebar-menu/extensions.svg" class="svg-icon" alt="">
                        <span>Settings</span>
                    </a>
                </router-link>
                                    
            
            </ul>
        </section>
    </aside>
</template>

<script>
export default {
    name: 'LeftSideBar',

}
</script>

<style>

</style>