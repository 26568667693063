<template>
    <div class="content text-center" style="display: flex; justify-content: center; align-items: center;">
        <div class="row paddinng-top: 60px">
            <!-- <img src="/nft/tokensninja.png" alt="tokens ninja" style="opacity: 0.5"> -->

            <!-- <div class="col-xl-3 mt-4">
                <img src="@/assets/images/svg_icons/eth.svg" class="pull-up" height="100" alt="">
            </div>
            <div class="col-xl-3 mt-4">
                <img src="@/assets/images/svg_icons/bnb.svg" class="pull-up" height="100" alt="">
            </div>
            <div class="col-xl-3 mt-4">
                <img src="@/assets/images/svg_icons/tron.svg" class="pull-up" height="100" alt="">
            </div>
            <div class="col-xl-3 mt-4">
                <img src="@/assets/images/svg_icons/link.svg" class="pull-up" height="100" alt="">
            </div> -->
        </div>
    </div>
</template>

<script>


export default {
    name: "Index",
}
</script>

<style scoped>

</style>
