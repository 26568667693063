<template>
    <div v-if="chain" class="mb-1">
        <span>Connected to Network: </span><span class="badge badge-info small">{{ chain.name }} [{{chain.chainId}}]</span>
    </div>
</template>

<script>
    export default {
        name: 'network-badge',
        props: ['chain']
    };
</script>

<style lang="scss" scoped></style>
