<template>
    <div>
        <Header />
        <LeftSideBar />
        
	        <div class="container-full" @click="toggleSideBar()" style="background: url('/nft/tokensninja-tbg.png') center repeat-x; background-attachment: fixed; background-position: center;">        
                <router-view class="router" style="min-height: calc(100vh - 50px); opacity: 0.95;" />
	        </div>
        
        <Footer />
        <RightSideBar />
    </div>
</template>

<script>
import { ethers } from 'ethers'
import Header from './components/Header'
import Footer from './components/Footer'
import LeftSideBar from './components/LeftSideBar'
import RightSideBar from './components/RightSideBar'

import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";


export default {
    name: 'App',
    components: {LeftSideBar, RightSideBar, Footer, Header},
    data() {
        return {
            EthProvider: null,
            EthChain: null,
            EthAccount: null,
            EthIsAdmin: false,

        }
    },
   
    async mounted () {                    
        await this.$store.dispatch('connectWeb3');
    },
     methods: {
         toggleSideBar() {
            //  console.log("closing sidebar");
             $('.control-sidebar').removeClass('control-sidebar-open')                                    
             $('body').addClass('sidebar-collapse')              
             $('body').removeClass('sidebar-open')                                    
            
         }
     }
}
</script>

<style lang="scss">

</style>
