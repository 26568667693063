const files = require.context('.', false, /\.json$/)
const chains = {}

files.keys().forEach((key) => {
  if (key === './chains.js') return
  chains[ key.replace(/(\.\/|\.json)/g, '') ] = files(key)
})

// console.log(chains);

export default chains