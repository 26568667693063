<template>
    <div class="content-wrapper">
        <div class="container-full">
            <section class="content mt-2" v-if="chainData">
                <div class="row">
                    <div class="col-xl-12 my-2">
                        <NetworkBadge :chain="chainData"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 mt-2" >
                        <div class="">
                            <h5 class="badge badge-sm pull-up p-2" :class="selectedTab? 'badge-success' : 'badge-dark'" @click="tabSelect(null)">Step 1 <i class="fa" :class="selectedTab? 'fa-arrow-right' : 'fa-arrow-down'"></i></h5>

                            <div class="dropdown" v-if="!selectedTab">
                                <button class="btn btn-rounded btn-outline btn-primary dropdown-toggle pull-up" type="button" data-toggle="dropdown">Choose what you need to build</button>
                                <div class="dropdown-menu dropdown-grid mt-2 p-5 justify-content-center">
                                    <a class="dropdown-item pull-up m-2" href="#" @click="tabSelect('ERC20')">
                                    <i class="cc ETH mr-0"></i> 
                                    <span class="title">ERC20</span>
                                    </a>
                                    <a class="dropdown-item pull-up m-2" href="#" @click="tabSelect('ERC777')">
                                    <i class="cc ETH mr-0"></i> 
                                    <span class="title">ERC777</span>
                                    </a>
                                    <a class="dropdown-item pull-up m-2" href="#" @click="tabSelect('ERC721')">
                                    <i class="cc ETH mr-0"></i> 
                                    <span class="title">ERC721 NFT</span>
                                    </a>

                                    <a class="dropdown-item pull-up m-2" href="#" @click="tabSelect('TokenSale')">
                                    <i class="cc ETH mr-0"></i> 
                                    <span class="title">Token Sale</span>
                                    </a>

                                    <a class="dropdown-item pull-up m-2" href="#" @click="tabSelect('ERC20')">
                                    <i class="cc ETH mr-0"></i> 
                                    <span class="title">Investment Pool</span>
                                    </a>

                                    <a class="dropdown-item pull-up m-2" href="#" @click="tabSelect('ERC20')">
                                    <i class="cc ETH mr-0"></i> 
                                    <span class="title">Token Protector</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ERC20 v-if="selectedTab === 'ERC20'" />
                <ERC777 v-if="selectedTab === 'ERC777'" />
                <ERC721 v-if="selectedTab === 'ERC721'" />
                <TokenSale v-if="selectedTab === 'TokenSale'" />

                <div class="row mt-2" v-if="selectedTab">
                    <div class="col-xl-12 text-xl-left text-center">
                        <a class="btn btn-sm btn-info pull-up wave"  @click.prevent="tabSelect(null)">Start Over</a>
                    </div>
                </div>

            </section>
            <section class="content" v-else>
                <div class="col-xl-12 text-center">Your browser is not connected to a blockchain.</div>
            </section>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import NetworkBadge from '@/components/NetworkBadge.vue'
import ERC20 from "../components/build/ERC20";
import ERC777 from "../components/build/ERC777";
import ERC721 from "../components/build/ERC721";
import TokenSale from "../components/build/TokenSale";
export default {
    name: "Build",
    components: {NetworkBadge, ERC20, ERC777, ERC721, TokenSale},
    props: ['TabSelect'],
    data() {
        return {
            selectedTab: this.$route.TabSelect
        }
    },
    computed: { 
        ...mapGetters(['contracts', 'account', 'chain', 'chainData', 'signer', 'provider']),
    },
    methods: {
        tabSelect(tab) {
            this.selectedTab = tab;
        }
    },
    mounted() {
        console.log("Tab Selected: " + this.TabSelect); 
        this.tabSelect(this.TabSelect);
    }

}
</script>

<style>

</style>