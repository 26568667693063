<template>
    <div>
        <span class="badge badge-light" v-if="credits && credits > 0">CREDITS: {{ credits }}</span>
        <span class="badge badge-light" v-else>PRICE PER AIRDROP TX: {{ pricePerTx }} {{chainData.nativeCurrency.symbol}}</span>
    </div>    
</template>

<script>
    import {mapGetters} from 'vuex';
    import {ethers} from 'ethers';
    import { EventBus } from "../event-bus";

    export default {
        name: "TxPrice",
        computed: {
            ...mapGetters(['contracts', 'chainData'])
        },
        data() {
            return {
                pricePerTx: null,
                credits: null,
            }
        },
        async mounted() {
            if (this.contracts) {
                await this.getPricePerTx();
            }

            EventBus.$on('price-per-tx-updated', () => {
                this.getPricePerTx();
            });
        },
        methods: {
            async getPricePerTx() {
                const {ERC20Airdropper} = this.contracts;
                this.pricePerTxInWei = await ERC20Airdropper.pricePerTx();
                this.pricePerTx = ethers.utils.formatEther(this.pricePerTxInWei);
                this.credits = parseInt((await ERC20Airdropper.credits(this.account)).toString());
            },
        },
        watch: {
            'contracts': async function (newVal, oldVal) {
                if(newVal && !oldVal) {
                    await this.getPricePerTx();
                }
            }
        }
    }
</script>

<style scoped>

</style>