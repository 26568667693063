<template>
    <div>
        <aside class="control-sidebar" id="rightSideBar">
        
            <div class="rpanel-title">
                <span class="pull-right btn btn-circle p-10">
                    <img src="/images/svg-icon/close.svg" data-toggle="control-sidebar" class="img-fluid svg-icon" alt="">
                </span> 
            </div>  <!-- Create the tabs -->
            <ul class="nav nav-tabs control-sidebar-tabs">                
                <li class="nav-item"><a href="#control-sidebar-home-tab" class="active" data-toggle="tab"><img src="/images/svg-icon/settings.svg" class="img-fluid svg-icon" alt=""></a></li>

            </ul>
        <!-- Tab panes -->
            <div class="tab-content" id="AppSettings">
                <!-- Home tab content -->
                
                <div class="tab-pane active" id="control-sidebar-home-tab">
                    <div class=" mb-10 pb-10 bb-1 light-on-off text-center" v-if="account">
                        <h4 class="control-sidebar-heading">Network</h4>    
                        
                              <span class="badge bg-info-light text-danger">
                                    <i class="fa fa-circle text-success"></i> <b>{{chainData.name}}</b>
                                </span>                           
                       
                               
                        
                        <div class="row mt-2">
                            <div class="col-6">
                                <small class="text-grey mx-1">
                                    {{ account.substring(0, 7) }}....{{ account.substring(account.length, account.length-6) }}                    
                                </small>
                                
                                
                            </div>
                            <div class="col-6 text-right">
                                <span class="badge bg-info-light text-success pull-up" @click="disconnectWeb3()">
                                    <a href="#" class="text-danger"><i class="fa fa-circle text-danger"></i> <b>Disconnect</b></a>
                                </span>       
                            </div>
                        </div>
                    </div>

                   <h4 class="control-sidebar-heading">Dark Mode</h4>                                        
                    <div class="flexbox mb-10 pb-10 bb-1 light-on-off">
                         <label for="toggle_left_sidebar_skin" class="control-sidebar-subheading">
                            Dark Mode On/Off
                        </label>
                        <label class="switch">
                            <input type="checkbox" data-mainsidebarskin="toggle" id="toggle_left_sidebar_skin">
                            <span class="switch-on font-size-30"><i class="mdi mdi-lightbulb-on"></i></span>
                            <span class="switch-off font-size-30"><i class="mdi mdi-lightbulb"></i></span>
                        </label>
                    </div>

                    
                    
                    <h4 class="control-sidebar-heading">Networks <small>Available</small></h4>                                        
                    <div class=" mb-10 pb-10 bb-1 light-on-off">
                        <div class="row">
                            <div class="col-6">
                                <label class="control-sidebar-subheading">Ethereum</label>
                            </div>
                            <div class="col-6 text-right">
                                 <span class="badge bg-info-light text-danger pull-up" @click="connectWeb3()">
                                    <a href="#" class="text-danger"><i class="fa fa-circle text-danger"></i> <b>Connect</b></a>
                                </span>                 
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="control-sidebar-subheading">BSC</label>
                            </div>
                            <div class="col-6 text-right">
                                <span class="badge bg-info-light text-danger pull-up" @click="connectBSC()">
                                    <a href="#" class="text-danger"><i class="fa fa-circle text-danger"></i> <b>Connect</b></a>
                                </span>                                                  
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <label class="control-sidebar-subheading">BSC Testnet</label>
                            </div>
                            <div class="col-6 text-right">
                                 <span class="badge bg-info-light text-danger pull-up" @click="connectPreBSC()">
                                    <a href="#" class="text-danger"><i class="fa fa-circle text-danger"></i> <b>Connect</b></a>
                                </span>                                              
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="control-sidebar-subheading">TRON</label>
                            </div>
                            <div class="col-6 text-right">
                                <span class="badge bg-info-light text-danger pull-up" @click="connectWeb3()">
                                    <a href="#" class="text-danger"><i class="fa fa-circle text-danger"></i> <b>Connect</b></a>
                                </span>                 
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.tab-pane -->
                <!-- Settings tab content -->
                
                <!-- /.tab-pane -->
            </div>
        </aside>
        
        <div class="control-sidebar-bg"></div>  
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

export default {
    name: 'RightSideBar',

     computed: {
        ...mapGetters(['lang', 'contracts', 'account', 'accountBalance', 'chain', 'chainData', 'signer', 'provider', 'listedTokens']),
        
    },
    methods: {
        ...mapActions(['changeLang','connectWeb3', 'disconnectWeb3']),       
            

        async old_connectWeb3() {
            // Web3Modal.toggleModal();

            

            const providerOptions = {
                walletconnect: {
                    display: {
                    name: "Wallet Connect"
                    },
                    package: WalletConnectProvider,
                    options: {
                    infuraId: "981fbf6b05d9405999f9e71474eb04a1" // required
                    }
                }
            };

            const web3Modal = new Web3Modal({
                network: "mainnet", // optional
                cacheProvider: false,
                theme: "dark",
                // disableInjectedProvider: true,
                providerOptions
                });

            web3Modal.clearCachedProvider();
           

            const modalprovider = await web3Modal.connect();

            console.log(modalprovider);

        },
        async connectBSC() {
            await window.ethereum.request({method: 'wallet_addEthereumChain', params: [{
                    "chainId": "0x38", 
                    "chainName": "Binance Smart Chain",
                    "nativeCurrency": {
                    "name": "BNB",
                    "symbol": "BNB",
                    "decimals": 18
                    },
                    "rpcUrls": ["https://bsc-dataseed.binance.org/", "https://bsc-dataseed1.defibit.io/", "https://bsc-dataseed1.ninicoin.io/"],
                    "blockExplorerUrls": ["https://bscscan.com/"],
                    "iconUrls": ["https://bscscan.com/images/svg/brands/bnb.svg?v=1.3"]
                }]
                }
            ).catch();
        },
        async connectPreBSC() {
            await window.ethereum.request({method: 'wallet_addEthereumChain', params: [{
                    "chainId": "0x61", 
                    "chainName": "Binance Smart Chain Testnet",
                    "nativeCurrency": {
                    "name": "BNB",
                    "symbol": "BNB",
                    "decimals": 18
                    },
                    "rpcUrls": [    
                        "https://data-seed-prebsc-1-s1.binance.org:8545",
                        "https://data-seed-prebsc-2-s1.binance.org:8545",
                        "https://data-seed-prebsc-1-s2.binance.org:8545",
                        "https://data-seed-prebsc-2-s2.binance.org:8545",
                        "https://data-seed-prebsc-1-s3.binance.org:8545",
                        "https://data-seed-prebsc-2-s3.binance.org:8545"
                    ],
                    "blockExplorerUrls": ["https://bscscan.com/"],
                    "iconUrls": ["https://bscscan.com/images/svg/brands/bnb.svg?v=1.3"]
                }]
                }
            ).catch();
        },
        // onLogout() {
        //     web3Connect.clearCachedProvider();
        //     // this.$store.dispatch('setupStaticWeb3');
        // },
    }

}
</script>

<style>

</style>