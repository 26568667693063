<template>
    <div class="row mt-2">
        <div class="col-xl-12 mt-2">    
            <h5 class="badge badge-sm badge-dark pull-up p-2">Step 2 <i class="fa fa-arrow-down"></i></h5>
                    
            <div class="box pull-up mt-2 p-2">
                <div class="text-center" v-if="confirmedTxsLinks.length < 1">
                    <h4>Build a Token Sale Contract</h4> 
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-12 text-center" style="padding-left: 30px;">
                            Create Your own Token Sale in Easy steps
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 text-center">                                                
                            <div>
                                <span class="badge badge-light">Price To Build: {{ tokenFees }} {{chainData.nativeCurrency.symbol}}</span>
                            </div>    
                        </div>
                    </div>
                    <b-form novalidate class="mt-2">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <b-form-group
                                        id="token-addr-group"
                                        label="ERC20 Token to distribute"
                                        label-for="token-addr"
                                        class=""
                                        description="this bit is quite important, double check it">

                                    <v-select label="tokenName" :options="tokensInAccount" @input="setSelected" :filter-by="filterBy" v-if="tokensInAccount.length > 0">
                                        <template v-slot:option="option">
                                            ({{ option.tokenSymbol }}) {{ option.tokenName }} - {{ option.contractAddress }}
                                        </template>
                                    </v-select>

                                    <b-form-input
                                        v-else                                
                                        id="token-addr"
                                        v-model="form.token"                                
                                        type="text"
                                        required
                                        placeholder="0xABC">
                                    </b-form-input>


                                </b-form-group>
                            </div>
                            <div class="col-md-12 col-sm-12">
                                <div style="padding-top: 50px;" class="text-center">
                                    <span class="mx-1 badge badge-dark" v-if="symbol">BALANCE: {{ balance }}</span>
                                    <span class="mx-1 badge badge-dark" v-if="symbol">SYMBOL: {{ symbol }}</span>
                                    <span class="mx-1 badge badge-dark" v-if="symbol">DP: {{ decimals }}</span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <b-form-input    
                                    id="token-symbol"
                                    v-model="rate"
                                    type="number"
                                    min="1"
                                    required
                                    :disabled="contractCode"
                                    :placeholder="`Selling Rate, how many ${symbol} for 1 ${chainData.nativeCurrency.symbol}`">
                                </b-form-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <b-form-input    
                                    id="token-name"
                                    v-model="wallet"                            
                                    type="text"
                                    required
                                    :disabled="contractCode"
                                    placeholder="Wallet to receive payments">
                                </b-form-input>                        
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <b-form-input    
                                    id="token-name"
                                    v-model="sourceWallet"                            
                                    type="text"
                                    required
                                    :disabled="contractCode"
                                    placeholder="Token Source Wallet">
                                </b-form-input>                        
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <b-form-input    
                                    id="token-name"
                                    v-model="allowanceAmount"                            
                                    type="number"
                                    min="1" 
                                    :max="balance"
                                    required
                                    :disabled="contractCode"
                                    :placeholder="`Allowance Amount max is ${balance}`">
                                </b-form-input>                        
                            </div>
                        </div>
                        <div class="row mt-2" v-if="readyToBuild">
                            <div class="col">
                                <b-button variant="primary" size="lg" class="float-right" @click="buildToken()" v-if="!contractCode" :disabled="building">
                                    <span v-if="!building">Build</span>
                                    <SmallSpinner v-else/>
                                </b-button>
                                <b-button variant="primary" size="lg" class="float-right btn-danger" @click="deployToken()" :disabled="deploying" v-else>                     
                                    <span v-if="!deploying">{{ deployButtonText }}</span>
                                    <SmallSpinner v-else/>
                                </b-button>
                            </div>
                        </div>
                    </b-form>

                </div>

                <div class="row mt-2 text-center" v-if="confirmedTxsLinks.length > 0">
                    <div class="col-12">
                        <div class="row mb-2">
                            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                <p class="info-text alert alert-success text-center mt-2" v-for="(link, key) in confirmedTxsLinks" :key="key">Your Token Sale have been successfuly created. View confirmation at <a :href="link">{{ link }}</a></p>
                                <img class="img img-responsive" src="@/assets/images/svg_icons/medal.svg" alt="Success" width="200"><br><br>                        
                            </div>
                        </div>
                        <div class="row mb-2 text-center">
                            <div class="col-4">
                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark">
                                    <template v-slot:header>
                                        <h6 class="mb-0">Token</h6>
                                    </template>
                                    <b-card-text>
                                        <strong>{{ symbol }}</strong>
                                    </b-card-text>
                                </b-card>
                            </div>
                            <div class="col-4">
                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark">
                                    <template v-slot:header>
                                        <h6 class="mb-0">Rate</h6>
                                    </template>
                                    <b-card-text>
                                        <strong>{{ rate }} {{ symbol }} / {{chainData.nativeCurrency.symbol}} </strong>
                                    </b-card-text>
                                </b-card>
                            </div>
                            <div class="col-4">
                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark">
                                    <template v-slot:header>
                                        <h6 class="mb-0">Allowance</h6>
                                    </template>
                                    <b-card-text>
                                        <strong>{{ ApprovedAllowance }}</strong>
                                    </b-card-text>
                                </b-card>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 text-center">
                                <a href="/build">Start Over</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {ethers} from 'ethers';
import Web3 from 'web3';
import {mapGetters, mapState} from "vuex";
import NetworkBadge from '@/components/NetworkBadge.vue'
import SmallSpinner from "@/components/SmallSpinner";
import erc20Abi from '@/abi/erc20.abi';
import Notify from 'bnc-notify';

export default {
    components: {NetworkBadge, SmallSpinner},
    data () {
        return {
            symbol: null,
            decimals: null,
            balance: null,
            rate: null,
            wallet: null,
            sourceWallet: null,
            allowanceAmount: null,
            approvedAllowance: null,
            contractCode: null,
            constructor: null,
            building: false,
            deploying: false,
            deployButtonText: 'Deploy',
            approving: null,
            approveTx: null,
            confirmedTxsLinks: [],
            form: {
                token: null,
            },
        }
    },
    computed: { 
        ...mapState(['tokensInAccount']),
        ...mapGetters(['contracts', 'account', 'chain', 'chainData', 'signer', 'provider']),
        readyToBuild() {
            if (this.form.token === null) return false;
            else if (this.rate === null) return false;
            else if (this.wallet === null) return false;
            else if (this.sourceWallet === null) return false;
            else return true;
        },
        fee() {
           return this.chainData.tokenFee;
        },
        tokenFees() {
            const web3 = new Web3(this.provider.provider);
            return web3.utils.fromWei(this.fee, 'ether');
        },
    },

    methods: {
        setSelected(value) {
            this.form.token = value ? value.contractAddress : '';
            this.checkAllowance();
        },
        async checkAllowance() {
            console.log('checkAllowance invoked');
            if (this.form.token && this.account) {
                const erc20 = new ethers.Contract(
                    this.form.token,
                    erc20Abi,
                    this.signer,
                );

                this.symbol = await erc20.symbol();
                this.decimals = await erc20.decimals();

                const weiBalance = (await erc20.balanceOf(this.account));


                this.balance = ethers.utils.formatUnits(weiBalance, this.decimals);
                console.log('balance', weiBalance.toString());

                if(this.confirmedTxsLinks.length > 0) {
                    this.approvedAllowance = ethers.utils.formatUnits((await erc20.allowance(this.account, this.confirmedTxsLinks[0])), this.decimals);
                    console.log('allowance', this.allowance.toString());
                }
            } else {
                this.decimals = '';
                this.symbol = '';
                this.balance = '';
                this.allowanceAmount = '';
            }
        },
        filterBy(option, label, search) {
            if(option.tokenSymbol && option.tokenSymbol.toLowerCase().indexOf(search.toLowerCase()) > -1){
                return true;
            }
            if(option.tokenName && option.tokenName.toLowerCase().indexOf(search.toLowerCase()) > -1){
                return true;
            }
            if(option.contractAddress && option.contractAddress.toLowerCase().indexOf(search.toLowerCase()) > -1){
                return true;
            }
            return false;
        },
        async pricePerTx(_wei) {
            return await ethers.utils.parseUnits(toString(_wei));
        },
        async buildToken() {
            this.building = true;
            // var output = await axios.get(`http://localhost:5001/airdropper-2f325/us-central1/main/api/build/tokensale/${this.fee}`);
            var output = await axios.get(`https://us-central1-airdropper-2f325.cloudfunctions.net/main/api/build/tokensale/${this.fee}`);
            console.log(output.data);

            this.contractCode = output.data.TokenSale;
            
            console.log(this.contractCode.abi);
            console.log(this.contractCode.evm.bytecode);
        },
        async deployToken() {
            const options = {
                dappId: '93470207-965c-447c-b3c5-31851a1fb2e7',
                networkId: this.chain.chainId,
                darkMode: true,
                desktopPosition: 'topRight',
            }
            const notify = Notify(options)


            const web3 = new Web3(this.provider.provider);
            const accounts = await web3.eth.getAccounts();

            // console.log(accounts);
            this.deploying = true;
            this.DeployButtonText = "Deploying...";



            const sendTransaction = async () => {
                try {
                    const tx = await new web3.eth.Contract(this.contractCode.abi).deploy({ data: this.contractCode.evm.bytecode.object, arguments: [this.rate, this.wallet, this.form.token, this.sourceWallet] }).send({from: this.account, value: this.fee});
                    
                    var txLink = `${this.chainData.explorerURL}/address/${tx._address}`;

                    // const receipt = await tx.wait(2);
                    console.log('tx receipt: ', tx);
                    this.confirmedTxsLinks.push(txLink);
                    
                    console.log("Approving transaction Invoked");
                    //then when approve the amount to the created contract
                    this.approving = true;
                    const erc20 = new ethers.Contract(
                        this.form.token,
                        erc20Abi,
                        this.signer,
                    );

                    const contractCall = {
                        methodName: 'approve',
                        params: [tx._address, ethers.utils.parseUnits(this.allowanceAmount, this.decimals)]
                    };
                
                    try {
                        const approveTx = await erc20.approve(...contractCall.params);
                        this.approveTx = `${this.chainData.explorerURL}/tx/${approveTx.hash}`;
                        console.log('Allowance receipt', this.approveTx);

                        this.approvedAllowance = ethers.utils.formatUnits((await erc20.allowance(this.account, tx._address)), this.decimals);

                        return approveTx.hash;
                        // return true;
                    } catch(e) {
                        console.log('Approve transaction error: '+e);

                        this.approving = false;
                        this.approveTx = null;
                        return null;
                    }

                    return tx.hash;                
                }
                catch (err) {
                    console.log(err);

                    this.deploying = false;
                    this.DeployButtonText = "Deploy";
                }
            };

            await notify.transaction({
                sendTransaction
            });


        
            // console.log(result)
            // console.log('Successfully deployed.');
            // console.log('View on Explorer at: https://rinkeby.etherscan.io/address/' + result._address);

        },

        
        
    },


    async mounted() {


    },
    watch: {
        'form.token': async function (newVal, oldVal) {
            this.checkAllowance();
        }
    }

}
</script>

<style>

</style>

