<template>
    <div class="row mt-2">
        <div class="col-xl-12 mt-2">    
            <h5 class="badge badge-sm badge-dark pull-up p-2">Step 2 <i class="fa fa-arrow-down"></i></h5>
                    
            <div class="box pull-up mt-2 p-2">
                <div class="text-center" v-if="confirmedTxsLinks.length < 1">
                    <h4>Build ERC777 Token</h4> 
                    <div class="row mt-5">
                        <div class="col">
                            <NetworkBadge :chain="chainData"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-12 text-center" style="padding-left: 30px;">
                            Create your own crypto token in easy steps
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 text-center">                                                
                            <div>
                                <span class="badge badge-light">Price To Build Token: {{ tokenFees }} {{chainData.nativeCurrency.symbol}}</span>
                            </div>    
                        </div>
                    </div>
                    <b-form novalidate class="mt-2">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <b-form-input    
                                    id="token-name"
                                    v-model="tokenName"                        
                                    type="text"
                                    required
                                    :disabled="contractCode"
                                    placeholder="Token Name">                            
                                </b-form-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <b-form-input    
                                    id="token-symbol"
                                    v-model="tokenSymbol"
                                    type="text"
                                    required
                                    :disabled="contractCode"
                                    placeholder="Token symbol">
                                </b-form-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
                                <b-form-input    
                                    id="token-name"
                                    v-model="tokenInitialSupply"                            
                                    type="number"
                                    required
                                    :disabled="contractCode"
                                    placeholder="Initial Supply">
                                </b-form-input>
                                
                            </div>
                        </div>
                        <div class="row mt-2" v-if="readyToBuild">
                            <div class="col">
                                <b-button variant="primary" size="sm" class="float-right" @click="buildToken()" v-if="!contractCode" :disabled="building">
                                    <span v-if="!building">Build</span>
                                    <SmallSpinner v-else/>
                                </b-button>
                                <b-button variant="primary" size="sm" class="float-right btn-danger" @click="deployToken()" :disabled="deploying" v-else>                     
                                    <span v-if="!deploying">{{ deployButtonText }}</span>
                                    <SmallSpinner v-else/>
                                </b-button>
                            </div>
                        </div>
                    </b-form>

                </div>

                <div class="row mt-2 text-center" v-if="confirmedTxsLinks.length > 0">
                    <div class="col-12">
                        <div class="row mb-2">
                            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                <p class="info-text alert alert-success text-center mt-2" v-for="(link, key) in confirmedTxsLinks" :key="key">Your Token have been successfuly created. View confirmation at <a :href="link">{{ link }}</a></p>
                                <img class="img img-responsive" src="@/assets/images/svg_icons/medal.svg" alt="Success" width="200"><br><br>
                                
                            </div>
                        </div>
                        <div class="row mb-2 text-center">
                            <div class="col-4">
                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark">
                                    <template v-slot:header>
                                        <h6 class="mb-0">Token Name</h6>
                                    </template>
                                    <b-card-text>
                                        <strong>{{ tokenName }}</strong>
                                    </b-card-text>
                                </b-card>
                            </div>
                            <div class="col-4">
                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark">
                                    <template v-slot:header>
                                        <h6 class="mb-0">Symbol</h6>
                                    </template>
                                    <b-card-text>
                                        <strong>{{ tokenSymbol }} </strong>
                                    </b-card-text>
                                </b-card>
                            </div>
                            <div class="col-4">
                                <b-card header-tag="header" footer-tag="footer" class="bg-yellowy text-dark">
                                    <template v-slot:header>
                                        <h6 class="mb-0">Balance</h6>
                                    </template>
                                    <b-card-text>
                                        <strong>{{tokenInitialSupply}}</strong> {{tokenSymbol}}
                                    </b-card-text>
                                </b-card>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {ethers} from 'ethers';
import Web3 from 'web3';
import {mapGetters, mapState} from "vuex";
import NetworkBadge from '@/components/NetworkBadge.vue'
import SmallSpinner from "@/components/SmallSpinner";
import Notify from 'bnc-notify';

export default {
    components: {NetworkBadge, SmallSpinner},
    data () {
        return {
            tokenName: null,
            tokenSymbol: null,
            tokenDecimals: null,
            tokenInitialSupply: null,
            contractCode: null,
            constructor: null,
            building: false,
            deploying: false,
            deployButtonText: 'Deploy',
            confirmedTxsLinks: [],
        }
    },
    computed: { 
        ...mapGetters(['contracts', 'account', 'chain', 'chainData', 'signer', 'provider']),
        readyToBuild() {
            if (this.tokenName === null) return false;
            else if (this.tokenSymbol === null) return false;
            else if (this.tokenInitialSupply === null) return false;
            else return true;
        },
        fee() {
           return this.chainData.tokenFee;
        },
        tokenFees() {
            const web3 = new Web3(this.provider.provider);
            return web3.utils.fromWei(this.fee, 'ether');
        },
    },

    methods: {
        async pricePerTx(_wei) {
            return await ethers.utils.parseUnits(toString(_wei));
        },
        async buildToken() {
            this.building = true;
            // var output = await axios.get(`http://localhost:5001/airdropper-2f325/us-central1/main/api/build/token/ERC777/${this.fee}`);
            var output = await axios.get(`https://us-central1-airdropper-2f325.cloudfunctions.net/main/api/build/token/ERC777/${this.fee}`);
            console.log(output.data.ERC777Token);

            this.contractCode = output.data.ERC777Token;
            
            console.log(this.contractCode.abi);
            console.log(this.contractCode.evm.bytecode);
        },
        async deployToken() {
            const options = {
                dappId: '93470207-965c-447c-b3c5-31851a1fb2e7',
                networkId: this.chain.chainId,
                darkMode: true,
                desktopPosition: 'topRight',
            }
            const notify = Notify(options)


            const web3 = new Web3(this.provider.provider);
            const accounts = await web3.eth.getAccounts();

            // console.log(accounts);
            this.deploying = true;
            this.DeployButtonText = "Deploying...";


            const updateUIWhenDone  = async (tx) => {
                var txLink = `${this.chainData.explorerURL}/address/${tx._address}`;

                // const receipt = await tx.wait(2);
                console.log('tx receipt', tx);
                this.confirmedTxsLinks.push(txLink);

                // Refresh UI
                this.deploying = false;
                this.DeployButtonText = "Deploy";
            };

            const sendTransaction = async () => {
                try {
                    const tx = await new web3.eth.Contract(this.contractCode.abi).deploy({ data: this.contractCode.evm.bytecode.object, arguments: [this.tokenName, this.tokenSymbol, this.tokenInitialSupply] }).send({from: this.account, value: this.fee});
                    updateUIWhenDone(tx);
                    return tx.hash;                
                }
                catch (err) {
                    console.log(err);

                    this.deploying = false;
                    this.DeployButtonText = "Deploy";
                }
            };

            await notify.transaction({
                sendTransaction
            });


        
            // console.log(result)
            // console.log('Successfully deployed.');
            // console.log('View on Explorer at: https://rinkeby.etherscan.io/address/' + result._address);

        },
        
    },


    async mounted() {

        // const ContractFactory = ethers.ContractFactory;
        // const factory = new ContractFactory(contractCode.abi, contractCode.evm.bytecode, this.signer);
        // const contract = await factory.deploy();
        // console.log(contract.deployTransaction);
        // console.log('Deployed Contract : '+contract.address);


        // web3 deployment
    }

}
</script>

<style>

</style>

