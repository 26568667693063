<template>
    <div class="box-body">							
        <div class="exchange-calculator">
            <div class="row">
                <div class="col-md-4 col-sm-12 d-flex justify-content-center mb-4">
                    
                    <vue-qrcode class="pull-up" :value="`${ saleDetails[0] }`" :options="{ width: 200 }" />
                    
                        <!-- <div class="row">
                        <p>You can buy by sending BNB directly to: 0x0000</p>
                        </div> -->
                </div>
                <div class="col-md-8 col-sm-12">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            <input type="number" class="form-control" name="coins-exchange" placeholder="" value="10" v-model="inputCurrency">                
                            <select class="currencyselect coins-exchange" name="state">
                                <option value="BTC">BNB</option>                                        
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            <div class="equal"> = </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            <input type="number" class="form-control" name="money-exchange" disabled placeholder="" :value="`${outputAmount}`">                
                            <select class="currencyselect money-exchange" name="state">
                                <option value="NINJA">{{ tokenDetails[2] }}</option>                                        
                            </select>

                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="text-center mt-15 mb-25">
                <a href="#" class="btn btn-warning mx-auto" @click="buyTokens((inputCurrency * 1e18))" :disabled="onGoingTransaction">BUY NOW</a>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import VueQrcode from '@chenfengyuan/vue-qrcode';

// import Web3 from 'web3';
import {ethers} from 'ethers';

export default {
    name: "BuyToken",
    components: {VueQrcode},
    props: ['token'],
    data() {
        return {
            tokenDetails: null,
            web3ethers: ethers,
            inputCurrency: 0,
            saleDetails: null,
            exchangeRate: 0,
            onGoingTransaction: false,
            txHash: null,
            txConfirmation: null,
        }
    },
    computed: { 
        ...mapGetters(['contracts', 'account', 'chain', 'chainData', 'signer', 'provider']),
        outputAmount() {
            return (this.inputCurrency * this.exchangeRate);
        },
        weiRaised(amount) {

            if(amount > 0) {
                // const web3 = new Web3(this.provider.provider);
                console.log(amount);
                return ethers.utils.formatUnits(amount, 18);
            }
        },
    },
    methods: {

        async buyTokens(etherAmount) {
            console.log(etherAmount, this.token, this.signer);

             const {LaunchPad} = this.contracts;  
             
        
            await LaunchPad.buyTokens(this.token, this.account, {value: ethers.utils.parseEther(this.inputCurrency)});
            // .on('transactionHash', (hash) => {
                
            //     // this.setState({ txHash: hash })
            //     console.log("TxHash: "+hash)
            // })
            // .on('confirmation', async (confirmationNumber, receipt) => {
            //     // let ethBalance = await window.web3.eth.getBalance(this.state.account)
            //     // this.setState({ ethBalance })
                
            //     // let tokenBalance = await this.state.token.methods.balanceOf(this.state.account).call()
            //     // this.setState({ tokenBalance: tokenBalance.toString() })

            //     // this.setState({ confirmedTransaction: true })
            //     console.log("confirmationNumber: "+ confirmationNumber)
            // })
            // .on('error', (error, receipt) => { 
            //     // this.setState({ loading: false })
            //     alert("Transaction not successful: "+ error.message);
            //     console.log(error)
            //     console.log(receipt)
            // })
        }
       
    },
    async mounted() {
        console.log("BuyToken Component mount");
        if (this.contracts) {
            const {LaunchPad} = this.contracts;           
            this.tokenDetails = await LaunchPad.listedTokens(this.token);   
            this.saleDetails = await LaunchPad.listedTokens_Sale(this.token);   
            this.exchangeRate = this.saleDetails[4]._hex;
        }
        
    }

}
</script>
