import Vue from 'vue'
import VueRouter from 'vue-router'
import Airdropv2 from '../views/Airdropv2.vue'
import Admin from "../views/Admin"
import Index from '../views/Index'
import Banner from '../components/Banner'
import Contact from '../views/Contact'
import Tutorial from '../views/Tutorial'
import AffilateProgramme from '../views/AffilateProgramme'
import Build from '../views/Build'
import LaunchToken from '../views/LaunchToken'
import LaunchPad from '../views/LaunchPad'
import TokenDetails from '../views/TokenDetails'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        components: {
            default: Index,
        }
    },
    {
        path: '/contact',
        name: 'contact',
        components: {
            default: Contact
        }
    },
    {
        path: '/tutorial',
        name: 'tutorial',
        components: {
            default: Tutorial
        }
    },
    {
        path: '/affiliate-programme',
        name: 'affiliate-programme',
        components: {
            default: AffilateProgramme
        }
    },
    {
        path: '/distribute',
        name: 'airdropv2',
        component: Airdropv2
    },
    {
        path: '/launch',
        name: 'Launch',
        component: LaunchPad
    },
    { path: '/tokens/:address', component: TokenDetails },
    {
        path: '/launch/token',
        name: 'Add Token',
        components: {
            default: LaunchToken
        }
    },
    {
        path: '/build',
        name: 'build',
        components: {
            default: Build
        }
    },
    {
        path: '/build-erc20',
        name: 'build ERC-20 token',
        component: Build,
        props: {
            TabSelect: 'ERC20',
        }
    },
    {
        path: '/build-erc777',
        name: 'build ERC-777 token',
        component: Build,
        props: {
            TabSelect: 'ERC777',
        }
    },
    {
        path: '/build-nft',
        name: 'build ERC-721 NFT token',
        component: Build,
        props: {
            TabSelect: 'ERC721',
        }
    },
    {
        path: '/build-tokensale',
        name: 'build a TokenSale',
        component: Build,
        props: {
            TabSelect: 'TokenSale',
        }
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkExactActiveClass: "active"
})

export default router
