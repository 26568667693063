import Vue from 'vue'
import Vuex from 'vuex'
import { ethers } from 'ethers'

import axios from 'axios'
import utils from '../utils'
import _ from 'lodash'
import ERC20Airdropper from '../truffleconf/ERC20Airdropper'
import AccessWhitelist from '../truffleconf/AccessWhitelist'
import LaunchPad from '../truffleconf/LaunchPad'

import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import MewConnect from "@myetherwallet/mewconnect-web-client";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        theme: 'dark',
        provider: null,
        signer: null,
        chain: null,
        contracts: null,
        accounts: null,
        account: null,
        accountBalance: null,
        chainData: null,
        tokensInAccount: [],
        listedTokens: [],
        lang: 'gb'
    },
    mutations: {
        updateWeb3Objects (state, {provider, signer, chain, contracts, accounts, account, accountBalance, chainData}) {
            Vue.set(state, 'provider', provider)
            Vue.set(state, 'signer', signer)
            Vue.set(state, 'chain', chain)
            Vue.set(state, 'contracts', contracts)
            Vue.set(state, 'accounts', accounts)
            Vue.set(state, 'account', account)
            Vue.set(state, 'accountBalance', accountBalance)
            Vue.set(state, 'chainData', chainData)
        },
        tokensInAccount (state, tokens) {
            console.log("tokensInAccount", tokens)
            state.tokensInAccount = _.sortBy(_.uniqBy(tokens, 'contractAddress'), 'tokenSymbol')
            console.log("tokensInAccount", state.tokensInAccount)
        },        
        setlistedToken (state, token) {            
            state.listedTokens.unshift(token);
            // Object.assign(state.listedTokens, token);
            
        },
        setLang (state, lang) {
            state.lang = lang
        },
        setTheme (state, mode) {
            state.theme = mode
        }
    },
    actions: {
        async disconnectWeb3({commit, dispatch}){            
            commit('updateWeb3Objects', {provider: null, signer: null, chain: null, contracts: null, accounts: null, account: null, accountBalance: null, chainData: null});
        },
        async connectWeb3({dispatch}) {
            console.log("connecting to network...");

            const providerOptions = {
                walletconnect: {
                    display: {
                        name: "Wallet Connect"
                    },
                    package: WalletConnectProvider,
                    options: {
                        infuraId: "981fbf6b05d9405999f9e71474eb04a1",
                         rpc: {
                            56: 'https://bsc-dataseed1.binance.org',
                            97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
                        }, 
                    }
                },
                mewconnect: {
                    package: MewConnect, // required
                    options: {
                      infuraId: "981fbf6b05d9405999f9e71474eb04a1",
                        rpc: {
                            56: 'https://bsc-dataseed1.binance.org',
                            97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
                        }, 
                    }
                  }
            };

            const web3Modal = new Web3Modal({
                // network: "mainnet", // optional
                cacheProvider: false,
                theme: this.state.theme,
                // disableInjectedProvider: true,
                providerOptions,                               
            }); 

            await web3Modal.clearCachedProvider();
           

            var modalprovider = await web3Modal.connect();

            console.log(modalprovider);
            
            var provider = new ethers.providers.Web3Provider(modalprovider);
            
            if(window.ethereum)
            {
                if(window.ethereum.isConnected()) {
                    // await web3Modal.off;
                }
                // var provider = new ethers.providers.Web3Provider(web3.currentProvider);

            
                window.ethereum.on('chainChanged', () => {
                    document.location.reload()
                });

                window.ethereum.on("accountsChanged", () => {
                    document.location.reload()
                });
                window.ethereum.on("close", () => {
                    document.location.reload()
                });
                window.ethereum.on("networkChanged", () => {
                    document.location.reload()
                });

            }
            else {            
                // var modalprovider =  new WalletConnectProvider({
                //     infuraId: "981fbf6b05d9405999f9e71474eb04a1", // Required
                //     rpc: {
                //         56: 'https://bsc-dataseed1.binance.org',
                //         97: 'https://data-seed-prebsc-1-s1.binance.org:8545'
                //     },                
                // })
            
                // await modalprov  ider.enable();

                var provider = new ethers.providers.Web3Provider(modalprovider);
                                
                provider.on("accountsChanged", () => {            
                    document.location.reload();
                });
                provider.on("chainChanged", () => {
                    document.location.reload();
                });

            }
                        
                    
            console.log(provider);

            const signer = provider.getSigner();
            const chain = await provider.getNetwork();
        
            dispatch('bootstrapWeb3', {provider, signer, chain});
        },
        async bootstrapWeb3 ({commit, dispatch}, {provider, signer, chain}) {
            const accounts = await provider.listAccounts()
            const account = accounts && accounts.length ? accounts[0] : null
            const accountBalance = ethers.utils.formatUnits(await provider.getBalance(account), 18);

            const airdropperAddress = utils.getContractAddressFromTruffleConf(ERC20Airdropper, chain.chainId)
            const accessWhitelistAddress = utils.getContractAddressFromTruffleConf(AccessWhitelist, chain.chainId)
            const LaunchPadAddress = utils.getContractAddressFromTruffleConf(LaunchPad, chain.chainId)

            const chainData = utils.getChainData(chain.chainId)

            console.log('Store, BSW3 AD: '+airdropperAddress);
            console.log('Store, BSW3 WL: '+accessWhitelistAddress);
            console.log('Store, BSW3 LP: '+LaunchPadAddress);

            const contracts = {
                ERC20Airdropper: new ethers.Contract(
                    airdropperAddress,
                    ERC20Airdropper.abi,
                    signer,
                ),
                AccessWhitelist: new ethers.Contract(
                    accessWhitelistAddress,
                    AccessWhitelist.abi,
                    signer
                ),
                LaunchPad: new ethers.Contract(
                    LaunchPadAddress,
                    LaunchPad.abi,
                    signer
                )
            }            

            commit('updateWeb3Objects', {provider, signer, chain, contracts, accounts, account, accountBalance, chainData})
            dispatch('getTokensInAccount', {account})
            dispatch('getTokenSales', {signer, chain})
        },
        async getTokenSales({state, commit}, {signer, chain}) {            
            console.log('storeAction getListedTokens');
            const LaunchPadAddress = utils.getContractAddressFromTruffleConf(LaunchPad, chain.chainId)

            const LaunchPadContract = new ethers.Contract(
                LaunchPadAddress,
                LaunchPad.abi,
                signer
            )

            
            let totalSales = await LaunchPadContract.totalListedTokens();   
            state.listedTokens = [];        
            for (let i=0; i <=totalSales; i++)
            {
                let token = await LaunchPadContract.tokensList(i);
                
                let details = await LaunchPadContract.listedTokens(token);
                let sale = await LaunchPadContract.listedTokens_Sale(token);
                let lock = await LaunchPadContract.listedTokens_Lock(token);

                let listedToken = {
                    startTime: sale[1],
                    address: details[4],                    
                    name: details[1],
                    symbol: details[2],
                    status: details[7],
                    URI: details[3],
                    details: {                        
                        owner: details[0],
                        tokenWallet: details[5],
                        haveLock: details[6]
                    },
                    sale: {
                        saleContract: sale[0],
                        startTime: sale[1],
                        endTime: sale[2],
                        payoutWallet: sale[3],
                        exchangeRate: sale[4],
                        platformFeePercentage: sale[5],
                        minContribution: sale[6],
                        maxContribution: sale[7],
                        targetWei: sale[8],
                        weiRaised: sale[9]
                    },
                    lock: {
                        isLock: lock[0],
                        lockPeriod: lock[1],
                        lockPercentage: lock[2],
                        lockContract: lock[3]
                    }
                
                }
                
                commit('setlistedToken', listedToken);
                // console.log(sales);
            }
            
        },
        async getTokensInAccount ({state, commit}, {account}) {
            console.log("storeAction getTokensInAccount, Account: "+ state.account);
            console.log('storeAction getTokensInAccount, Chain: '+state.chain.chainId);
            
            
                

                if (state.chain.chainId === 56 || state.chain.chainId === 97) {
                    var rootPath = `https://us-central1-airdropper-2f325.cloudfunctions.net/main/api/chain/${state.chain.chainId}/wallet/tokens/${account}`
                }
                else {
                    var rootPath = `https://us-central1-erc20-airdropper.cloudfunctions.net/main/api/chain/${state.chain.chainId}/wallet/tokens/${account}`
                }
                
            
                const {data} = await axios.get(rootPath, {
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            })
            if (data && data.tokens) {
                commit('tokensInAccount', data.tokens)
            }
        },
        changeLang ({commit}, lang) {
            commit('setLang', lang)
        },
        changeTheme ({commit}, themeMode) {
            commit('setTheme', themeMode)
        }
    },
    getters: {
        contracts: state => state.contracts,
        account: state => state.account,
        accountBalance: state => state.accountBalance,
        chainData: state => state.chainData,
        chain: state => state.chain,
        signer: state => state.signer,
        provider: state => state.provider,
        lang: state => state.lang,
        listedTokens: state => state.listedTokens,
    },
    modules: {}
})
